@import '../utils/variables.scss';

.headerContainer {
  height: 80px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  background-color: $appRedColor;
  padding: 20px 60px 0px 60px;
  .linkDiv {
    height: 45px;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    a {
      font-size: 16px !important;
      font-family: HelveticaRoman !important;
      color: $whiteColor !important;
    }
  }
  .linkDivActive {
    @extend .linkDiv;
    background-color: #96191f !important;
    a {
      font-size: 16px !important;
      font-family: HelveticaBold !important;
      color: $whiteColor !important;
      margin-top: 2px !important;
    }
  }

  .linkSmallDiv {
    height: 45px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    a {
      font-size: 14px !important;
      font-family: HelveticaRoman !important;
      color: $whiteColor !important;
    }
  }
  .linkSmallDivActive {
    @extend .linkSmallDiv;
    background-color: #96191f !important;
    a {
      font-size: 14px !important;
      font-family: HelveticaBold !important;
      color: $whiteColor !important;
      margin-top: 2px !important;
    }
  }
  .logoutContainer {
    color: $whiteColor;
    cursor: pointer;
    border: none;
    &:hover {
      color: $whiteColor !important;
      font-family: HelveticaBold;
      border: none;
    }
  }
}

.linkIcon {
  margin-right: 5px;
  margin-bottom: 7px;
}
.linkMenu {
  font-weight: 600;
}

.ant-dropdown-menu-item > a {
  color: $appRedColor;
  font-weight: normal;
}
