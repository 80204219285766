.section {
  margin: 0;
  padding: 0 !important;
  display: flex;
  align-items: center;
}

.container {
  margin-bottom: 5px;
}

.whiteSpace {
  margin: 0 30px;
}
