$blueColor: #21638b;
$greyColor: #6d6e71;
$lightGrey: #f0f0f0;
$blackColor: #000000;
$appRedColor: #ce202f;
$whiteColor: #ffffff;
$warningIconYellow: #ffdd00;
$yellowColor: #b97d36;
$criticalColor: #790000;
$poorColor: #ef4123;
$goodColor: #62bb46;
$excellentColor: #bfd730;
$greenColor: #567c47;
$lightGreenColor: #d8f0cf;
$inActiveChipColor: #939598;
