@font-face {
  font-family: 'HelveticaBold';
  src: local('HelveticaBold'), url('./fonts/Helvetica-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'HelveticaRoman';
  src: local('HelveticaRoman'), url('./fonts/Helvetica-Roman.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'HelveticaHeavy';
  src: local('HelveticaHeavy'), url('./fonts/Helvetica-Heavy.ttf') format('truetype');
  font-weight: bold;
}
body {
  font-family: HelveticaRoman;
}
