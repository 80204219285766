@import './utils/variables.scss';

@mixin borderRadius4() {
  border-radius: 4px !important;
}

@mixin selectBorder() {
  border: 1px solid $blackColor !important;
  border-radius: 5px !important;
}

.ant-input {
  @include borderRadius4();
}

.ant-input-number {
  @include borderRadius4();
}

.ant-picker {
  @include borderRadius4();
}

.ant-select {
  @include borderRadius4();
}

.ant-btn {
  border-radius: 6px !important;
}

.requiredField:before {
  content: '*';
  color: red;
  margin-right: 3px;
}

.requiredFieldAfter:after {
  content: '*';
  color: red;
  margin-right: 3px;
}

.selectBoxContainer {
  .selectBoxLabel {
    font-size: 18px;
    color: $blackColor;
  }
  .selectBox {
    margin-left: 15px;
    .ant-select-selector {
      border-radius: 5px !important;
    }
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  @include selectBorder();
}

.passwordHeading {
  margin: 0px 30px;
  .ant-divider-horizontal {
    margin: 0px !important;
  }
}

.selectedPastureRow {
  background-color: #f1f6ff !important;
}

.nestedTableColumnTitle {
  color: $blackColor !important;
}

.pastureRow {
  background-color: #e2e2e2 !important;
}

// ---------------------------------------------------------UsersScreen----------------------------------------------------------------

.tabContainer {
  .react-tabs__tab--selected {
    background-color: #fff !important;
    color: $blackColor !important;
    border-radius: 0px;
    border-color: $blackColor !important;
    border-bottom: 1px solid $whiteColor !important;
  }
  .react-tabs__tab {
    background: $blueColor;
    color: $whiteColor;
    border-color: $blueColor;
    margin: 2px 2px 0px 2px;
    border-radius: 0px;
    border-bottom: 1px solid $blueColor;
  }
  .react-tabs__tab-list {
    border-color: $blackColor;
  }
}

.drawerContainer {
  .ant-drawer-header {
    border-bottom: 2px solid #b3b3b3;
  }
  .ant-drawer-body {
    padding: 0;
  }
}

// ant drag upload component
.ant-upload.ant-upload-drag {
  height: 500px !important;
}

//--------------------------------------------------- modal divider -------------------------------------------------------
.ant-modal-body {
  padding: 0px !important;
  .ant-divider {
    border-top: 2px solid #dfdada;
    margin-bottom: 0px;
  }
  .ant-divider-vertical {
    height: 50px;
    border-left: 2px solid #dfdada;
  }
}
.ant-modal-content {
  border-radius: 10px !important;
}

//-----------------------------------------------------------title of pop confirm ----------------------------------------------
.popUpMessage {
  font-size: 14px;
  margin-left: 8px;
}

//---------------------------------------------------------------cows summary table --------------------------------------------
.tableContainer {
  .ant-table-cell {
    padding: 3px 8px !important;
  }
}

//-------------------------- ant picker ----------------------------------- //

div.ant-picker.ant-picker-range {
  @include selectBorder();
}

.flexBetween {
  display: flex;
  justify-content: space-between;
}

#customRefAreaLegend {
  display: flex;
  justify-content: flex-start;
  .recharts-legend-item.legend-item-0 {
    font-weight: 600 !important;
    svg.recharts-surface {
      display: none !important;
    }
  }
  .recharts-legend-item {
    svg.recharts-surface {
      path.recharts-legend-icon {
        stroke: #333 !important;
        stroke-width: 0.5;
      }
    }
  }
}

.boldTitle {
  font-weight: bold;
  font-size: 15px;
}

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 5px 5px !important;
}

.displayBlank {
  color: transparent !important;
  user-select: none !important;
}

.ant-checkbox-wrapper.ant-checkbox-group-item {
  margin-bottom: 5px !important;
}

.hideFilterIcon {
  span.ant-table-filter-trigger-container {
    display: none !important;
  }
}

// set active switch color to app red color
.ant-switch-checked {
  background-color: $greenColor !important;
}

.ant-collapse-header {
  background-color: $greenColor !important;
  padding: 6px 10px 6px 15px !important;
  color: $whiteColor !important;
}
.ant-collapse-arrow {
  font-size: 20px !important;
}

// css to add scrolling effect
html {
  scroll-behavior: smooth;
}

// css to centrally align  input values
.analysisScreenDatePicker {
  .ant-picker-input {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  .ant-picker-range {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
// antd description item  custom style to reduce bottom padding
.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 5px !important;
}

// img loading style when user upload image on add image screen
.imgLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8c8c8c;
  opacity: 0.7;
  height: 100vh;
  .ant-spin-text {
    color: white !important;
  }
  .ant-spin-dot-item {
    background-color: white !important;
  }
}

// new styling based on Updates
.producerTable {
  .ant-table-thead > tr > th {
    padding: 12px 8px 8px 8px !important;
  }
  .ant-table-tbody > tr > td {
    padding: 8px 10px !important;
    border-bottom: 0px !important;
  }

  .ant-table-thead .ant-table-cell {
    background-color: $blueColor !important;
    color: $whiteColor !important;
    font-size: 16px !important;
    font-family: HelveticaBold !important;
  }
  .ant-table-cell {
    font-size: 16px !important;
    font-family: HelveticaRoman !important;
  }
}

// style to handle hover focus and active state of producers screen button(ie black button style)
.producerBtnStyleContainer {
  .ant-btn {
    background: $blackColor !important;
    color: $whiteColor !important;
    display: flex !important;
    align-items: center !important;
    border-color: $blackColor !important;
  }
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn-active {
    opacity: 0.7 !important;
    background: $blackColor !important;
    border-color: transparent;
    color: $whiteColor !important;
  }
}
// class to set style of content div for screen used in appRoutes
.ScreenContainer {
  min-height: calc(100vh - 170px);
}

.formSubmitBtn {
  background: $blackColor !important;
  color: $whiteColor !important;
  margin: 10px !important;
  border: none !important;
}

.formCancelBtn {
  color: $greyColor !important;
  margin: 10px 0px 0px 10px !important;
  border: none !important;
  p {
    text-decoration: underline !important;
    color: $greyColor !important;
    font-size: 18px;
    vertical-align: middle;
    margin-bottom: 0px;
  }
}

// new styling based on Updates
.analysisTable {
  .ant-table.ant-table-small .ant-table-thead > tr > th {
    padding: 8px 8px 6px 8px !important;
  }
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 8px 10px !important;
    border-bottom: 0px !important;
  }

  .ant-table-thead .ant-table-cell {
    background-color: $greenColor !important;
    color: $whiteColor !important;
    font-size: 16px !important;
    font-family: HelveticaBold !important;
  }
  .ant-table-cell {
    font-size: 16px !important;
    font-family: HelveticaRoman !important;
  }
}
.selectionRow {
  font-weight: 500;
  background-color: $lightGreenColor !important;
}

.analysisScreenMultipleSelect {
  .ant-select-multiple .ant-select-selection-item {
    background-color: $greenColor !important;
  }
  .ant-select-multiple .ant-select-selection-item-content {
    color: $whiteColor !important;
  }
  .ant-select-multiple .ant-select-selection-item-remove {
    color: $whiteColor !important;
  }
}
