@import '../utils/variables.scss';

.helpText {
  font-style: italic;
  font-weight: 700 !important;
}

.errorText {
  color: $appRedColor;
  padding-left: 30px;
}

.inputContainer {
  display: flex !important;
  align-items: flex-start !important;
  .labelName {
    width: 150px;
    text-align: end;
    font-size: 15px;
    margin-top: auto;
    margin-bottom: auto;
  }
}
