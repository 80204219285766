@import '../utils/variables.scss';

.modalButton {
  border: none !important;
  width: 249px;
  box-shadow: none;
  font-size: 20px;
  color: $blackColor;
  height: 50px;
}

.infoFilledIcon {
  color: $blueColor !important;
  font-size: 16px;
  margin-left: 10px;
}

.formContainer {
  .formLabel {
    padding-left: 25px;
    padding-top: 10px;
    font-size: 20px;
  }
  .formLabel:after {
    content: '*';
    color: red;
  }
  .inputField {
    margin: 0px 20px;
    width: 360px;
    border: 1px solid;
  }
  .buttonContainer {
    .cancelButton {
      @extend .modalButton;
      border-right: 1px solid #dfdada !important;
      border-radius: 0px !important;
    }

    .addButton {
      @extend .modalButton;
      color: #2b78e4;
      border-left: 1px solid #dfdada !important;
      border-radius: 0px !important;
    }
  }
}


.deleteButton {
  border: 1px solid $appRedColor !important;
  color: $appRedColor !important;
  background-color: #eee !important;
}
