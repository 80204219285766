.chartWrapper {
  margin-top: 50px;
}

svg:not(:root) {
  overflow: visible !important;
}

.recharts-wrapper .recharts-cartesian-grid-vertical line:last-child {
  stroke-opacity: 0;
}
