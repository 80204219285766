.warningText {
  border: 2px solid #ff9900;
  padding: 3px;
  padding-right: 21px;
  font-size: 16px;
  border-radius: 5px;
  background-color: #ffefc2;
  margin-left: auto;
  margin-right: auto;
  .warningIcon {
    color: #ff9900;
    font-size: 18px;
    vertical-align: middle;
    padding: 0px 20px;
    margin-bottom: 3px;
  }
}
