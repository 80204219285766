@import '../utils/variables.scss';

@mixin inputField() {
  border-radius: 5px !important;
  border: 2px solid grey !important;
}

.outerContainer {
  height: 100vh;
  width: 100%;
  background-image: url(../assets/icons/SignInPhoto.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  display: flex;
}

.innerRedContainer {
  background-color: $appRedColor;
  width: 400px;
  height: 300px;
  margin-left: 150px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
