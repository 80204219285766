@import '../utils/variables.scss';

.alignContentAndItemsToCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageHeading {
  padding-left: 40px;
  padding-top: 10px;
  margin-bottom: 0px;
  font-family: HelveticaBold;
}

.image {
  width: 100%;
  height: 150px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.imageDescription {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  text-align: center;
}
.imageDescHeading {
  font-family: HelveticaBold;
  text-transform: capitalize;
  margin-bottom: 0;
  color: $whiteColor;
  font-size: 16px;
}
