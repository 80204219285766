.infoText {
  display: inline-flex;
  align-items: center;
  max-width: fit-content;
  border: 2px solid #2b78e4;
  padding: 5px;
  font-size: 14px;
  padding-right: 20px;
  border-radius: 5px;
  background-color: #e3f1ff;
  .infoIcon {
    color: #2b78e4;
    margin: 0px 20px;
  }
}
